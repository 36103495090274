<template>
  <section class="chat-conversation-discussion__wrapper">
    <div
      class="chat-conversation-discussion__messages-wrapper"
      ref="messages">
      <ol class="chat-conversation-discussion__messages-list">
        <li
          v-for="(message, index) in messages"
          :key="`message-${index}`"
          :class="[
            'chat-conversation-discussion__messages-item',
            { 'chat-conversation-discussion__messages-item--author': message.isAuthor },
          ]">
          <Icon
            v-if="!message.isAuthor"
            :variant="'user-outline'"
            class="mr-sm">
          </Icon>
          <Paragraph
            :color="message.isAuthor ? 'white' : 'black'"
            :class="[
              'chat-conversation-discussion__messages-item-message',
              { 'chat-conversation-discussion__messages-item-message--author': message.isAuthor },
            ]">
            {{ message.content }}
          </Paragraph>
        </li>
      </ol>
    </div>

    <form
      @submit.prevent="handleSendMessage"
      class="chat-conversation-discussion__new-message-wrapper"
      novalidate>
      <textarea
        v-model="message"
        type="text"
        :placeholder="'Écrivez un message ici (trad)'"
        id="chat-conversation-input"
        wrap="hard"
        rows="1"
        class="chat-conversation-discussion__new-message-input">
      </textarea>
      <Button
        v-if="message.length"
        type="submit"
        class="ml-lg">
        Envoyer (trad)
      </Button>
    </form>
  </section>
</template>

<script>

import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon           from '../../../components/atoms/Icon/a-Icon.vue';
import Button         from '../../../components/atoms/Button/a-Button.vue';


export default {
  name: 'o-ChatConversationDiscussion',
  components: {
    Paragraph,
    Icon,
    Button,
  },
  data: () => ({
    message: '',
    messages: [
      {
        content: 'Altera sententia est, quae definit amicitiam paribus officiis ac voluntatibus. Hoc quidem est nimis exigue et exiliter ad calculos vocare amicitiam, ut par sit ratio acceptorum et datorum. Divitior mihi et affluentior videtur esse vera amicitia nec observare restricte, ne plus reddat quam acceperit; neque enim verendum est, ne quid excidat, aut ne quid in terram defluat, aut ne plus aequo quid in amicitiam congeratur.',
      },
      {
        content: 'Altera sententia est, quae definit amicitiam paribus officiis ac voluntatibus.',
        isAuthor: true,
      },
      {
        content: 'Altera sententia est, quae definit amicitiam paribus officiis ac voluntatibus. Hoc quidem est nimis exigue et exiliter ad calculos vocare amicitiam, ut par sit ratio acceptorum et datorum. Divitior mihi et affluentior videtur esse vera amicitia nec observare restricte, ne plus reddat quam acceperit; neque enim verendum est, ne quid excidat, aut ne quid in terram defluat, aut ne plus aequo quid in amicitiam congeratur.',
      },
      {
        content: 'Altera sententia est, quae definit amicitiam paribus officiis ac voluntatibus. Hoc quidem est nimis exigue et exiliter ad calculos vocare amicitiam, ut par sit ratio acceptorum et datorum. Divitior mihi et affluentior videtur esse vera amicitia nec observare restricte, ne plus reddat quam acceperit; neque enim verendum est, ne quid excidat, aut ne quid in terram defluat, aut ne plus aequo quid in amicitiam congeratur.',
      },
      {
        content: 'Altera sententia est, quae definit amicitiam paribus officiis ac voluntatibus. Hoc quidem est nimis exigue et exiliter ad calculos vocare amicitiam, ut par sit ratio acceptorum et datorum. Divitior mihi et affluentior videtur esse vera amicitia nec observare restricte, ne plus reddat quam acceperit; neque enim verendum est, ne quid excidat, aut ne quid in terram defluat, aut ne plus aequo quid in amicitiam congeratur.',
      },
      {
        content: 'Altera sententia est, quae definit amicitiam paribus officiis ac voluntatibus. Hoc quidem est nimis exigue et exiliter ad calculos vocare amicitiam, ut par sit ratio acceptorum et datorum. Divitior mihi et affluentior videtur esse vera amicitia nec observare restricte, ne plus reddat quam acceperit; neque enim verendum est, ne quid excidat, aut ne quid in terram defluat, aut ne plus aequo quid in amicitiam congeratur.',
      },
      {
        content: 'Altera sententia est, quae definit amicitiam paribus officiis ac voluntatibus. Hoc quidem est nimis exigue et exiliter ad calculos vocare amicitiam, ut par sit ratio acceptorum et datorum. Divitior mihi et affluentior videtur esse vera amicitia nec observare restricte, ne plus reddat quam acceperit; neque enim verendum est, ne quid excidat, aut ne quid in terram defluat, aut ne plus aequo quid in amicitiam congeratur.',
      },
    ],
  }),
  mounted() {
    this.$refs.messages.scrollTo({ top: this.$refs.messages.scrollHeight });
  },
  methods: {
    handleSendMessage() {
      this.message = '';
    },
  }
}

</script>

<style lang="scss">

.chat-conversation-discussion {
  &__wrapper {
    display: flex;
    flex-direction: column;
    font-family: var(--font-stack-secondary);
  }

  &__messages-wrapper {
    flex: 1 1 auto;
    padding: calc(1.5 * var(--space-lg)) var(--space-lg);
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  &__messages-list {
    display: flex;
    flex-direction: column;
  }

  &__messages-item {
    display: flex;
    max-width: 80%;
    align-self: flex-start;

    &:not(:last-child) {
      margin-bottom: var(--space-base);
    }

    &--author {
      align-self: flex-end;
    }
  }

  &__messages-item-message {
    padding: var(--space-md);
    padding-top: var(--space-sm);
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-background);

    &--author {
      background-color: var(--color-black);
    }
  }

  &__new-message-wrapper {
    display: flex;
    align-items: center;
    padding: var(--space-base) var(--space-lg);
    background-color: var(--color-grey-light);
  }

  &__new-message-input {
    flex: 1 0 auto;
    padding: var(--space-sm) var(--space-lg);
    font-size: var(--text-xs);
    line-height: var(--lineheight-md);
    border-radius: var(--rounded-xl);
    resize: none;
    white-space: initial;
    overflow: initial;
    text-align: left;

    &::placeholder {
      font-size: var(--text-xxs);
      font-style: italic;
      color: var(--color-grey-neutral);
    }
  }
}

</style>
